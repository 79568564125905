
const flipCards = (e) => {
  const cards = document.querySelectorAll(".project-card");
    cards.forEach(function(card, index) {
      card.addEventListener('mouseenter', function(){
        console.log('hello');
        card.classList.add('active')
      })
      card.addEventListener('mouseleave', function(){
        card.classList.remove('active')
      })

    })
}

export { flipCards }
